<template>
  <compliance-booking :reschedule="true" />
</template>

<script>
import ComplianceBooking from "./complianceBooking.vue";

export default {
  components: {
    ComplianceBooking,
  },
};
</script>

<style></style>
